import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { ModalBottomSheet, BottomButtonType, Button } from '@class101/ui';
import { imageSrc } from '../components/interface';
import { LongContent } from './ModalBottomSheet';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "modal-bottom-sheet"
    }}>{`Modal Bottom Sheet`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ModalBottomSheet} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<ModalBottomSheet\n  opener={<Button>Open Modal</Button>}\n  title=\"수강권 주문 상세\"\n  subTitle=\"수강권을 한번 더 확인해 주세요.\"\n  successText=\"확인\"\n  cancelText=\"취소\"\n>\n  This is Modal\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Open Modal</Button>} title="수강권 주문 상세" subTitle="수강권을 한번 더 확인해 주세요." successText="확인" cancelText="취소" mdxType="ModalBottomSheet">
    This is Modal
  </ModalBottomSheet>
    </Playground>
    <blockquote>
      <p parentName="blockquote"><inlineCode parentName="p">{`opener`}</inlineCode>{`에는 onClick이벤트가 가능한 컴포넌트를 작성해야합니다.`}</p>
    </blockquote>
    <h2 {...{
      "id": "with-state"
    }}>{`With State`}</h2>
    <blockquote>
      <p parentName="blockquote">{`State로 사용할 경우 `}<inlineCode parentName="p">{`onSuccess`}</inlineCode>{`, `}<inlineCode parentName="p">{`onCancel`}</inlineCode>{`, `}<inlineCode parentName="p">{`onClose`}</inlineCode>{`를 설정하는것을 추천합니다.
`}<inlineCode parentName="p">{`onOpen`}</inlineCode>{`은 동작하지 않습니다.
설정을 하지 않을 시 Modal이 state값과는 다르게 동작할 수 있습니다.`}</p>
    </blockquote>
    <Playground __position={2} __code={'() => {\n  const [opened, setOpened] = React.useState(false)\n  const openModal = () => setOpened(true)\n  const closeModal = () => setOpened(false)\n  return (\n    <>\n      <Button onClick={openModal}>Open Modal</Button>\n      <ModalBottomSheet\n        title=\"State Modal\"\n        subTitle=\"this is state modal\"\n        opened={opened}\n        cancelText=\"취소\"\n        onCancel={closeModal}\n        onClose={closeModal}\n      >\n        Modal Children\n      </ModalBottomSheet>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [opened, setOpened] = React.useState(false);

        const openModal = () => setOpened(true);

        const closeModal = () => setOpened(false);

        return <>
        <Button onClick={openModal} mdxType="Button">Open Modal</Button>
        <ModalBottomSheet title="State Modal" subTitle="this is state modal" opened={opened} cancelText="취소" onCancel={closeModal} onClose={closeModal} mdxType="ModalBottomSheet">
          Modal Children
        </ModalBottomSheet>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "button-action"
    }}>{`Button Action`}</h2>
    <p><inlineCode parentName="p">{`onOpen`}</inlineCode>{`에서 false를 리턴할 경우 열리지 않습니다.`}</p>
    <Playground __position={3} __code={'<ModalBottomSheet\n  opener={<Button>Show Modal</Button>}\n  title=\"수강권 주문 상세\"\n  successText=\"확인\"\n  onOpen={() => confirm(\'open?\')}\n  onSuccess={close => {\n    alert(\'확인 됨\')\n    close()\n  }}\n>\n  This is Modal\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal</Button>} title="수강권 주문 상세" successText="확인" onOpen={() => confirm('open?')} onSuccess={close => {
        alert('확인 됨');
        close();
      }} mdxType="ModalBottomSheet">
    This is Modal
  </ModalBottomSheet>
    </Playground>
    <h2 {...{
      "id": "option"
    }}>{`Option`}</h2>
    <p><inlineCode parentName="p">{`closeable`}</inlineCode>{`를 설정하면 modal바깥쪽을 클릭할때와 x버튼을 클릭할 때 닫힙니다. 기본으로 설정되어 있습니다.`}</p>
    <p>{`content가 `}<inlineCode parentName="p">{`max-height`}</inlineCode>{`를 넘었을 때
`}<inlineCode parentName="p">{`hideScroll`}</inlineCode>{`를 설정하면 스크롤이 숨겨지게 됩니다.`}</p>
    <p>{`Modal에 이미지를 꽉차게 설정하고 싶을 때 `}<inlineCode parentName="p">{`removeContentPadding`}</inlineCode>{`를 설정하면 됩니다.`}</p>
    <Playground __position={4} __code={'<ModalBottomSheet\n  opener={<Button>Show Modal</Button>}\n  closeable={false}\n  hideScroll\n  title=\"수강권 주문 상세\"\n  successText=\"확인\"\n>\n  <LongContent />\n</ModalBottomSheet>\n<ModalBottomSheet\n  opener={<Button>Show Modal</Button>}\n  title=\"이미지 상세보기\"\n  cancelText=\"닫기\"\n  removeContentPadding\n>\n  <img style={{ width: \'100%\' }} src={imageSrc} />\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal</Button>} closeable={false} hideScroll title="수강권 주문 상세" successText="확인" mdxType="ModalBottomSheet">
    <LongContent mdxType="LongContent" />
  </ModalBottomSheet>
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal</Button>} title="이미지 상세보기" cancelText="닫기" removeContentPadding mdxType="ModalBottomSheet">
    <img style={{
          width: '100%'
        }} src={imageSrc} />
  </ModalBottomSheet>
    </Playground>
    <h2 {...{
      "id": "button-attributes"
    }}>{`Button Attributes`}</h2>
    <p><inlineCode parentName="p">{`successAttributes`}</inlineCode>{`와 `}<inlineCode parentName="p">{`cancelAttributes`}</inlineCode>{`로 버튼의 속성을 정할 수 있습니다.`}</p>
    <Playground __position={5} __code={'<ModalBottomSheet\n  opener={<Button>Show Modal</Button>}\n  title=\"Modal\"\n  successText=\"확인\"\n  successAttributes={{ loading: true }}\n>\n  This is Modal\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal</Button>} title="Modal" successText="확인" successAttributes={{
        loading: true
      }} mdxType="ModalBottomSheet">
    This is Modal
  </ModalBottomSheet>
    </Playground>
    <h2 {...{
      "id": "bottom-button-type"
    }}>{`Bottom Button Type`}</h2>
    <p><inlineCode parentName="p">{`bottomButtonType`}</inlineCode>{`으로 버튼의 정렬 방식을 정할 수 있습니다.`}</p>
    <Playground __position={6} __code={'<ModalBottomSheet\n  opener={<Button>Show Modal (Row)</Button>}\n  title=\"Modal\"\n  bottomButtonType={BottomButtonType.Row}\n  successText=\"확인\"\n  cancelText=\"취소\"\n>\n  This is Modal\n</ModalBottomSheet>{\' \'}\n<ModalBottomSheet\n  opener={<Button>Show Modal (Column)</Button>}\n  title=\"Modal\"\n  bottomButtonType={BottomButtonType.Column}\n  successText=\"확인\"\n  cancelText=\"취소\"\n>\n  This is Modal\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal (Row)</Button>} title="Modal" bottomButtonType={BottomButtonType.Row} successText="확인" cancelText="취소" mdxType="ModalBottomSheet">
    This is Modal
  </ModalBottomSheet>
  {' '}
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal (Column)</Button>} title="Modal" bottomButtonType={BottomButtonType.Column} successText="확인" cancelText="취소" mdxType="ModalBottomSheet">
    This is Modal
  </ModalBottomSheet>
    </Playground>
    <h2 {...{
      "id": "no-ssr"
    }}>{`No SSR`}</h2>
    <p>{`ModalBottomSheet는 React Portal을 Server Side Rendering에 사용하기 위해 `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{`때 렌더링 됩니다.`}</p>
    <p>{`하지만 SSR을 사용하지 않으면 `}<inlineCode parentName="p">{`noSSR`}</inlineCode>{`속성을 사용하여 다른 컴포넌트와 동시에 랜더링되게 할 수 있습니다.`}</p>
    <Playground __position={7} __code={'<ModalBottomSheet\n  opener={<Button>Show Modal</Button>}\n  title=\"Mounted Modal\"\n  successText=\"확인\"\n  noSSR\n>\n  content\n</ModalBottomSheet>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ModalBottomSheet,
      BottomButtonType,
      Button,
      imageSrc,
      LongContent,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ModalBottomSheet opener={<Button mdxType="Button">Show Modal</Button>} title="Mounted Modal" successText="확인" noSSR mdxType="ModalBottomSheet">
    content
  </ModalBottomSheet>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      